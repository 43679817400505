<template>
  <!-- On: "tw-bg-indigo-600", Off: "tw-bg-gray-200" -->
  <span
    @click="$emit('toggle')"
    role="checkbox"
    tabindex="0"
    v-bind:class="[checked ? 'tw-bg-orange-500' : 'tw-bg-gray-200']"
    class="tw-bg-gray-200 tw-relative tw-inline-flex tw-flex-shrink-0 tw-h-7 tw-w-12 tw-border-2 tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-shadow-outline"
  >
    <!-- On: "tw-translate-x-5", Off: "tw-translate-x-0" -->
    <span
      v-bind:class="[checked ? 'tw-translate-x-5' : 'tw-translate-x-0']"
      class="tw-translate-x-0 tw-inline-block tw-h-6 tw-w-6 tw-rounded-full tw-bg-white tw-shadow tw-transform tw-transition tw-ease-in-out tw-duration-200"
    ></span>
  </span>
</template>

<script>
export default {
    props: {
      checked: { type: Boolean, default: false }
    }
}
</script>
