<template>
    <div class="tw-mt-6">
        <dl class="tw-divide-y tw-divide-gray-200">
            <NotificationToggle
                key="all"
                label="All tags (not in use)"
                :checked="tags.lengh > 0 && subscriptions.length === tags.length"
                @toggleall="toggleAll"
            />
            <NotificationToggle
                v-for="tag in tags"
                v-bind:key="tag.id"
                :label="tag.name"
                :checked="subscriptions.includes(tag.id)"
                @toggle="toggleOne"
            />
        </dl>
    </div>
</template>

<script>
import ToggleComponent from '@/components/ui/Inputs/ToggleComponent.vue'
import NotificationToggle from '@/components/Settings/NotificationToggle.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
    components: {
        ToggleComponent,
        ClipLoader,
        NotificationToggle,
    },
    data() {
        return {
            tags: [],
            subscriptions: [],
            subscribe_to_all: false,
        }
    },
    methods: {
        getTags() {
            try {
                this.$http
                    .get(`${process.env.VUE_APP_API_ROOT}tags/`)
                    .then(response => {
                        this.tags = response.data.results.map(item => {
                            return {
                                ...item,
                                subscribed: false,
                            }
                        })
                        console.log(this.tags)
                    })
                
            } catch (e) {
                // catch exception
            }
        },
        getSubscriptions() {
            try {
                this.$http
                    .get(`${process.env.VUE_APP_API_ROOT}subscriptions/`)
                    .then(response => {
                        this.subscriptions = response.data.results.map(item => {
                            return item.tag_id
                        })
                        console.log(this.subscriptions)
                    })
                
            } catch (e) {
                // catch exception
            }
        },
        // this method shall be updated based on api behaviour
        toggleSubscribe(id, subscribe) {
            if (subscribe) {
                return this.$http.delete(
                    `${process.env.VUE_APP_API_ROOT}subscriptions/${id}/`
                )
            } else {
                return this.$http.post(
                    `${process.env.VUE_APP_API_ROOT}subscriptions/`,
                    {
                        tag_id: id,
                    }
                )
            }
        },
        toggleOne(id, subscribe, done) {
            this.toggleSubscribe(id, subscribe)
                .then(() => {
                    if (!subscribe) {
                        console.log("subscribing to: " + id)
                        this.subscriptions = [...this.subscriptions, id]
                    } else {
                        console.log("unsubscribing to: " + id)
                        this.subscriptions = this.subscriptions.filter(i => i !== id)
                    }
                })
                .catch(() => {})
                .finally(() => done())
        },
        toggleAll(subscribe, done) {
            return done()
            this.toggleSubscribe(-1, subscribe)
                .then(() => {
                    if (subscribe) {
                        this.subscriptions = this.tags.map(item => item.id)
                    } else {
                        this.subscriptions = []
                    }
                })
                .catch(() => {})
                .finally(() => done())
        },
    },
    created() {
        this.getTags()
        this.getSubscriptions()
        
        
    },
}
</script>
