<template>
    <div class="tw-mt-20 sm:tw-mt-10 tw-divide-y tw-divide-gray-200">
        <div class="tw-space-y-1">
            <h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                SMS Notifications
            </h3>
            <p class="tw-max-w-2xl tw-text-sm tw-text-gray-500">
                Please enable the tags you would like to receive SMS notifications. These settings only effect your currently selected team.
            </p>
        </div>
        <NotificationSettings class="tw-mt-6" />
    </div>
</template>

<script>
import NotificationSettings from '@/components/Settings/NotificationSettings.vue'

export default {
    components: {
        NotificationSettings,
    },
}
</script>

<style></style>
