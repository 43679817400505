<template>
    <div class="tw-py-5 tw-grid tw-grid-cols-3 tw-gap-4">
        <dt
            class="tw-text-md tw-font-medium tw-text-gray-500"
            id="auto-update-option-label"
        >
            {{ label }}
        </dt>
        <dd
            class="tw-flex tw-text-sm tw-text-gray-900 tw-mt-0 tw-col-span-2"
            style="margin-bottom: 0"
        >
            <div class="tw-ml-auto">
                <clip-loader
                    :loading="loading"
                    color="#63c2de"
                    size="15px"
                    class="tw-inline tw-pb-1 tw-mr-2"
                ></clip-loader>
                <ToggleComponent :checked="checked" @toggle="toggleSubscribe" />
            </div>
        </dd>
    </div>
</template>

<script>
import ToggleComponent from '@/components/ui/Inputs/ToggleComponent.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
    props: ['label', 'checked'],
    components: {
        ToggleComponent,
        ClipLoader,
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        done() {
            this.loading = false
        },
        toggleSubscribe() {
            const id = this.$vnode.key
            const subscribe = this.checked ? true : false

            this.loading = true

            if (id === 'all') {
                this.$emit('toggleall', subscribe, this.done)
            } else {
                this.$emit('toggle', id, subscribe, this.done)
            }
        },
    },
}
</script>

<style></style>
